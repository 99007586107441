body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: black;
}

.btn {
  background-color: #f7b80f;
  border: none;
  padding: 10px 30px;
  color: rgb(0, 0, 0);
  font-size: 16px;
  cursor: pointer;
  margin: 10px 0px;
}
.btn:active {
  background-color: #fad87a;
  transition: 0.1s;
}

.btn:disabled {
  color: rgb(255, 255, 255);
  background-color: #747474;
}

.err {
  font-size: 13px;
  color: #ff000099;
  font-weight: 600;
  text-align: left;
}
