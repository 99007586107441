.category-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.category-item input[type="checkbox"] {
  width: auto;
  cursor: pointer;
}
.user-store input {
  margin-bottom: 5px;
}
.user-store .err {
  margin-bottom: 10px;
}
.category-list {
  height: 200px;
  overflow: overlay;
  margin-bottom: 10px;
}
.new-book-title {
  background-color: #ddf097;
  margin: 0px;
  padding: 15px;
  color: #4C5F06;
  /* border-bottom: 2px solid #00000045; */
}
.user-store .select-country {
  margin-bottom: 5px;
}
.block-inp-new-book {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.block-inp-new-book label {
  width: 20%;
  font-weight: 600;
}
.block-inp-new-book input {
  width: 100%;
  border-color: #00000045;
}
.block-inp-new-book textarea {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 35px;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #00000045;
  outline: none;
}
.input-new-book {
  width: 50%;
  margin-bottom: 8px;
}
@media (max-width: 540px) {
  .block-inp-new-book {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
  .input-new-book {
    width: 90%;
  }
}
