.conditions h2 {
  border-bottom: 2px solid #cccccc;
}
.conditions a {
  text-decoration: underline;
}
.conditions p {
  overflow: auto;
  text-align: justify;
}
