.home{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.category-menu{
    width: 22%;
}
.pag-comp{
    width: 76%;
}
.block-cookie{
    display: flex;
    justify-content: center;
}
@media (max-width:700px) {
    .home {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column-reverse;
    }
    .pag-comp {
        width: 100%;
    }
}
