.footer {
  background-color: #4c5f06;
  color: white;
  min-height: 250px;
}
.logo-text-footer {
  font-size: 22px;
  color: white;
}
.footer a {
  color: rgba(255, 255, 255, 0.767);
}
.footer p {
  margin: 7px 0px;
  color: rgba(255, 255, 255, 0.767);
  font-size: 14px;
}
.pointer {
  cursor: pointer;
}
.footer img {
  height: 120px;
}
.inf-block {
  width: 30%;
}
.year-logo {
  font-size: 13px;
  color: #ffffffb8;
  font-weight: 300;
}

.footer-flex {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-items: start;
}
.footer-item {
  width: 90%;
  margin: 10px 5%;
}
.footer-logo {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-c {
  text-align: center;
  padding: 10px 0px;
  color: rgba(255, 255, 255, 0.767);
  font-size: 10px;
}

@media (max-width: 540px) {
  .footer-item {
    width: 100%;
  }
  .footer {
    min-height: 500px;
  }
  .footer-flex {
    grid-template-columns: repeat(1, 1fr);
  }
  .inf-block {
    width: 100%;
  }
}
