.handler-order {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-dark);
}
.status-order {
  font-weight: 600;
  color: #427a33;
}
.story-order .cart {
  margin-bottom: 15px;
  padding: 10px;
}
