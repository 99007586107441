.user,
.order {
  background-color: white;
  box-shadow: 0px 4px 13px 0px #80808017;
}
.user {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.handler {
  width: 100%;
  background-color: #ddf097;
  margin-bottom: 20px;
}
.handler h2 {
  margin-left: 10px;
  color: #4c5f06;
}
.label-date {
  width: 53%;
  text-align: left;
}
.change-pass input {
  width: 73%;
}
.change-pass .error {
  width: 75%;
  font-size: 13px;
  color: #ff000099;
  font-weight: 600;
  text-align: left;
}
.change-pass .visible-block {
  width: 90%;
}
.block-user {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.user .err {
  width: 52%;
}
input {
  padding: 10px;
  width: 50%;
  margin-bottom: 10px;
  outline: none;
  font-size: 16px;
  border: 2px solid;
  border-color: white white #00000045 white;
  display: block;
}

.user input:focus {
  border-color: white white #819143 white;
}

.btn-user a {
  color: #f7b80f;
  font-size: 20px;
  font-weight: 600;
}
.btn-user img {
  width: 70px;
  height: 60px;
}

.select-country {
  padding: 10px;
  width: 53%;
  margin-bottom: 16px;
  outline: none;
  font-size: 16px;
  border: 2px solid #00000045;
}

.change-pass {
  width: 50%;
  height: fit-content;
  padding: 10px 20px;
  margin: 20px 0px;
  border: 3px solid #4d5f0b;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.block-date {
  display: flex;
  align-items: center;
  width: 54%;
  justify-content: space-between;
}
.btn-user {
  display: flex;
  align-items: center;
}
.block-date .select-country {
  margin: 0px 10px;
}

.change-pass h3 {
  text-align: center;
}

.status-change {
  background-color: #00800033;
  color: #4d5f0b;
  padding: 20px;
  width: 50%;
  border-left: 3px solid #4d5f0b;
  margin: 10px 0px;
}

@media (max-width: 600px) {
  .user input {
    width: 90%;
  }
  .user .select-country {
    width: 98%;
  }
  .change-pass {
    width: 85%;
  }
}
@media (max-width: 540px) {
  input {
    width: 90%;
  }
  .select-country {
    width: 100%;
  }
  .order textarea {
    width: 90%;
  }
}
