.order-in-story {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #547c5491;
}
.user-store .accordion p {
  width: 100%;
}
.user-store-story .accordion {
  background-color: #d3e1d39e;
  padding: 0px 9px;
}
.yellow-text {
  color: #f9ce4f;
  font-weight: 600;
}

.red-text {
  color: red;
  font-weight: 600;
}

.green-text {
  color: green;
  font-weight: 600;
}

.order-table {
  width: 100%;
  text-align: center;
}
.first-column img {
  height: auto;
}

.user-store-story .accordion_title {
  font-size: 16px;
}

.img-order-story {
  width: 60%;
}

.tbl-price {
  width: 100%;
  text-align: left;
}
.handler-order {
  background-color: #ddf097;
}
.modal1 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
  animation-name: appear;
  animation-duration: 300ms;
}

.modal1-dialog {
  width: 100%;
  max-width: 450px;
  position: relative;
  margin: 0 20px;
  max-height: calc(100vh - 40px);
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: slide-in;
  animation-duration: 0.5s;
  background: #f5f5f7;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  color: #1e1e1e;
  font-family: var(--font--main);
}

.modal1-header,
.modal1-footer {
  display: flex;
  align-items: center;
  padding: 1rem;
  flex-direction: column;
  font-size: 27px;
}

.modal1 h3 {
  text-align: center;
  margin: 15px;
  font-size: 27px;
}

.modal1-header {
  border-bottom: 1px solid #dbdbdb;
  justify-content: space-between;
}

.modal1-footer {
  border-top: 1px solid #dbdbdb;
  justify-content: flex-end;
}

.modal1-close {
  cursor: pointer;
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal1-body {
  overflow: auto;
}

.modal1-content {
  padding: 1rem 4em;
}
@media (max-width: 540px) {
  .order-in-story {
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .order-table__book-title,
  .order-table {
    font-size: 13px;
    font-weight: 300;
    text-align: left;
  }
  .order-in-story h3 {
    margin: 10px 0px;
  }
  .tbl-price {
    font-size: 13px;
    font-weight: 300;
    text-align: left;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-150px);
  }
  to {
    transform: translateY(0);
  }
}
