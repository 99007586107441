.user-store .cart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.user-store-option {
  display: flex;
}
.user-store-option .btn {
  margin: 0px 10px;
  text-align: center;
}
.sup-status {
  width: 40%;
  text-align: end;
}
.order-in-story h3 {
  width: 35%;
}
.sup-name {
  width: 25%;
}
@media (max-width: 540px) {
  .user-store {
    padding: 0;
  }
  .user-store .cart {
    display: flex;
    flex-direction: column;
  }
  .user-store-option {
    display: flex;
    flex-direction: column;
  }
  .user-store .btn {
    margin: 5px 0px;
  }
}
