.search{
    padding: 8px 1%;
    width: 98%;
    margin: 30px 0px;
    box-shadow: 0px 0px 20px #b2abab7d;
}
.add-your-userBook{
    background: #F7B80F;
    border: none;
    width: 22%;
    height: 60px;
    font-weight: 600;
    font-size: 15px;
    color: #4C5F06;
}
.img-banner{
    width: 100%;
    box-shadow: 0px 0px 20px #b2abab7d;
}
.inp-search{
    width: 100%;
    padding: 10px 5px 0px;
    font-size: 18px;
    background-color: inherit;
    border: none;
    border-bottom: solid 1px #ACACAC;
    margin-left: 12px;
    outline:none;
}
.pointer{
    cursor: pointer;
}
.inp-block{
    display: flex;
    align-items: center;
}
.disc-search{
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    color: #ACACAC;
}
.hero-optional{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (max-width:540px) {

.add-your-userBook {
    display: none;
}
.search {
    width: 100%;
    margin-bottom: 0px;
}
}