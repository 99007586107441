.pagination-panel {
  text-align: center;
}
.pagination-panel .btn {
  color: black;
}
.pagination-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: center;
}

.pagination-list ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0px;
}
.pagination-list li {
  padding: 5px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.pagination-list .active {
  background-color: #ffb700;
}
