.pay-order-btn {
  height: 60px;
  display: flex;
  justify-content: flex-end;
}
.story-order h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ddf097;
  padding: 15px 20px;
  margin: 0px;
  color: var(--color-dark);
}
.story-order .pag-comp {
  width: 100%;
}
.story-order .handler-order {
  background-color: white;
}
.track-num {
  overflow: auto;
}
