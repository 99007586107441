
.form-signup{
    display: flex;
    flex-direction: column;
}

.btn-registration{
    background-color: #F7B80F;
    color: white;
    padding: 8px 35px;
    margin-top: 10px;
    border: none;
    font-size: 16px;
}