.img-edit{
    width: 80%;
}
.all-img-edit{
    display: flex;
}
.option-book-edit{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 20px;
}

.close-img{
    height: 20px;
    cursor: pointer;
}

.edit-book-title{
    background-color: #ddf097;
    margin: 0px;
    padding: 15px;
    color: #4C5F06;
    /* border-bottom: 2px solid #00000045; */
}
