.successful-order {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.successful-order-block {
  display: flex;
  align-items: center;
  justify-content: center;
}
.successful-order img {
  width: 50%;
}
.successful-order-content {
  width: 50%;
  text-align: center;
}
.successful-order a {
  background-color: #ffbb00;
  padding: 10px 30px;
  margin-top: 10px;
}

@media (max-width: 500px) {
  .successful-order img {
    width: 90%;
  }
  .successful-order-block {
    flex-direction: column;
  }
}
