.enter,
.reset {
  background-color: white;
  padding: 10px 20px;
  box-shadow: 0px 4px 13px 0px #80808017;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.enter h3 {
  color: #819143;
  font-size: 25px;
}

.err-enter {
  background-color: #fff4d4;
  padding: 20px;
  color: #ffc107;
  font-weight: 600;
  border-left: 4px solid #ffc107;
}
.warn-enter {
  color: gray;
  font-size: 14px;
}
.warn-enter a {
  color: gray;
  font-size: 14px;
  text-decoration: underline;
}

.enter input {
  padding: 10px;
  width: -webkit-fill-available;
  margin-bottom: 10px;
  outline: none;
  border-width: 0px;
  border-bottom: 2px solid #00000045;
}

.visible-block-enter {
  display: flex;
}

.visible-status {
  cursor: pointer;
  height: 19px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 10px;
}

.link-enter {
  cursor: pointer;
  text-decoration: underline;
  color: #f7b80f;
  font-weight: 500;
}

.link-reset {
  cursor: pointer;
  margin-left: 10px;
  text-decoration: underline;
  color: #4c5f06;
  font-weight: 500;
}

@media (max-width: 540px) {
  .alredy-block {
    display: flex;
    flex-direction: column;
  }
}
