.accordion__section {
  display: flex;
  flex-direction: column;
}

.accordion {
  color: #000000;
  cursor: pointer;
  /* margin-bottom: 6px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

.accordion__section img {
  height: 8px;
  transition: 1s;
}
.up-arr {
  transform: rotateX(160deg);
}
/* .down-arr{

} */

.accordion__content {
  margin-left: 10px;
}
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

.rotate {
  transform: rotate(90deg);
}

.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

.accordion__text {
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}
.accordion p {
  margin: 0;
}
.activeAcc {
  animation: openAccordion 3s;
}
.accordion__title {
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  margin: 7px 0px;
}
@keyframes openAccordion {
  0% {
    max-height: 0px;
  }
  50% {
    max-height: 400px;
  }
  90% {
    max-height: 800px;
  }
  100% {
    max-height: max-content;
  }
}
@media (max-width: 500px) {
  .accordion__content {
    margin-left: 0;
  }
}
