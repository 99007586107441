.header {
  background-color: #f8f8f8;
  font-weight: 600;
  min-height: 75px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #262e07;
  /* width: 86%; */
  padding: 15px 8%;
  position: sticky;
  top: 0;
  z-index: 10;
}
.btn-sign-out {
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
.header-setting-menu img {
  height: 40px;
}
.header-logo {
  display: flex;
  align-items: center;
}

.header-logo img {
  height: 100px;
}

.logo-img {
  height: 90%;
  display: flex;
  align-items: center;
}
.logo-text {
  font-size: 23px;
  margin-left: 10px;
}

.header-setting-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
}

.btn-entry {
  margin: 0px 8%;
}
@media (max-width: 800px) {
  .logo-text {
    font-size: 16px;
  }
  .logo-img {
    width: 100%;
    justify-content: center;
  }
  .header-logo img {
    height: 40px;
  }
  .header-setting-menu {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .info-cart {
    display: none;
  }
  .header {
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }
}
