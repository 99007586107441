.cookie{
    position: fixed;
    bottom: 10px;
    width: 70%;
    margin: auto;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 15px rgba(128, 128, 128, 0.353);
}
.cookie-desc{
        display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.cookie-desc p{
    margin: 0;
    font-size: 14px;
}
.cookie-desc img{
    height: 70px;
    margin-right: 20px;
}
.cookie .btn{
    margin: 0 10% 0 0;
    padding: 5px 20px;
}
.btn-ok{
    text-align: end;
}
.btn-ok .btn{
    color: black;
}