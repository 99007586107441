.access-key {
  height: 90px;
  margin-top: 50px;
}
.visible-block {
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.visible-block img {
  height: 19px;
}
.redeem {
  min-height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.redeem .err {
  text-align: center;
}
@media (max-width: 800px) {
  .redeem {
    text-align: center;
  }
  .visible-block {
    width: 90%;
  }
}
