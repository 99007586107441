.book-option-all {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.book-img {
  /* width:-webkit-fill-available;
    height: 450px; */
  width: 100%;
  background-color: #8080804a;
}

.book-option {
  width: 44%;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
}

.book-option p {
  margin-top: 10px;
}

.book-table {
  width: 100%;
  border-collapse: collapse;
}

.book-table tr {
  border: 1px solid;
  border-color: #847b7b82 #f8f8f8;
  height: 45px;
}

.book-table td {
  padding-left: 10px;
}

.isbn {

}
.book-price {
  display: flex;
  flex-direction: column;
}
.book-price-option {
  display: flex;
  align-items: center;
}
.book-quantity {
  display: flex;
  justify-content: space-around;
  width: 12%;
  border: 1px solid gray;
  padding: 5px 0px;
  font-size: 20px;
}
.btn-to-card {
  background-color: #f7b80f;
  padding: 8px 35px;
  margin-left: 10px;
  cursor: pointer;
}
.slider-container {
  width: 45%;
  margin-left: 15px;
  /* z-index: -1; */
}
.quantity-btn {
  cursor: pointer;
}
.btn-arrow {
  right: -15px;
  position: absolute;
}
.btn-arrow-left {
  position: absolute;
  left: -15px;
}

/* @media (max-width:1200px) {
    
}
@media (max-width:900px) {
    
} */
@media (max-width: 750px) {
  .slider-container {
    width: 93%;
    margin-left: 15px;
  }
  .book-option {
    width: 100%;
    padding-left: 0px;
  }
  .book-option-all {
    flex-direction: column;
  }
}
@media (max-width: 540px) {
}
