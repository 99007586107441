.reset input {
  width: 30%;
  margin: 0;
}
.reset .err {
  width: 30%;
}
.reset img {
  height: 100px;
}
.status-text {
  background-color: #ddf3d9;
  padding: 20px;
  margin-bottom: 10px;
  color: #1f6f23;
  border-left: 3px solid #1f6f23;
}
.reset .btn {
  margin: 10px;
  white-space: nowrap;
}
.retry-reset {
  background-color: #f0f0f0;
  padding: 15px 30px;
  color: grey;
  font-weight: 600;
  cursor: pointer;
}
.retry-reset:active {
  background-color: #fad87a;
  transition: 0.1s;
}
.retry-reset:disabled {
  color: rgb(255, 255, 255);
  background-color: #747474;
}

.block-reset-btn {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.block-reset-btn a {
  width: 50%;
}
@media (max-width: 500px) {
  .reset input,
  .reset .err {
    width: 90%;
  }
  .reset img {
    height: auto;
    min-height: 100px;
    width: 40%;
  }
  .block-reset-btn {
    width: 100%;
    height: auto;
    flex-direction: column;
  }
  .block-reset-btn a {
    width: 75%;
  }
}
