.handler-cat-menu {
  margin: 0;
  background-color: #ffb700;
  padding: 20px 10px;
  text-align: center;
  /* font-weight: 600; */
  /* color: #0f5e06; */
}
.active-cat a {
  color: #e9c000;
}
.active-cat {
  color: #e9c000;
}
/* .active-cat p{
    color: #e9c000;
} */
.cat-menu {
  /* color: #7a7a7a; */
  background-color: white;
  font-weight: 300;
  padding: 10px;
  cursor: pointer;
}
.item-menu:hover {
  color: #e9c000;
}
.item-menu {
  margin-bottom: 5px;
}
.magazins {
  background-color: #cae171;
  border: none;
  padding: 15px;
  font-weight: 600;
  font-size: 15px;
  color: #4c5f06;
  text-align: left;
  margin: 10px 0px;
  width: 100%;
}
.subcat-menu {
  margin-left: 15px;
}
@media (max-width: 700px) {
  .magazins {
    display: none;
  }
}
