.order textarea {
  width: 95%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #00000045;
  outline: none;
}
.paypal-buttons-context-iframe {
  z-index: 0;
}
.disable-btn {
  display: none;
}
/* iframe .paypal-button {
  display: none !important;
} */
@media (max-width: 540px) {
  .order textarea {
    width: 90%;
  }
}

.user-order__stores {
  padding: 5px;
}
