.handler-order {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.status-order {
  font-weight: 600;
  color: #4c5f06;
}
.story-order .cart {
  margin-bottom: 15px;
  padding: 10px;
}
