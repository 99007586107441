.search-book-user {
  padding: 8px 1%;
  width: 98%;
  box-shadow: 0px 0px 20px #b2abab7d;
}
.book-store a {
  width: 17%;
}
.book-store img {
  /* width: 96%; */
  margin-right: 10px;
}
.title-book {
  width: 100%;
  text-align: center;
  font-size: 1em;
}
.title-book h2 {
  font-size: 1em;
}
.status-block {
  width: 11%;
  font-size: 1em;
}
.trash-btn {
  width: 3%;
  cursor: pointer;
}
.trash-btn img {
  width: 100%;
}
.block-books-store {
  display: flex;
}
.btns-books {
  width: 30%;
  margin-right: 2%;
  position: sticky;
  height: fit-content;
  top: 100px;
}
.block-books-store .sort {
  height: 45px;
  padding: 3px;
}
.block-add-book {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}
.btns-books img {
  height: 30px;
  filter: invert(1);
}
.price-td {
  width: 9%;
}
.inp-check {
  width: 23px;
  height: 17px;
  cursor: pointer;
}
.btns-books .btn {
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.block-unblock-text {
  width: 100%;
  text-align: center;
  color: #ffffff;
}

.td-name {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.add-book {
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-book img {
  height: 30px;
  margin: 0px 5px;
}
.img-book {
  height: 100px;
  width: auto;
}

.nowrap {
  white-space: nowrap;
}
.mobile-table-book {
  display: none;
}
@media (max-width: 540px) {
  .table-books-shop {
    display: none;
  }
  .mobile-table-book {
    display: block;
  }
  .user-store {
    padding: 20px;
  }
  .mobile-item-book {
    padding: 5px 0px;
    border-bottom: 1px solid #80808030;
  }
  .block-books-store {
    display: flex;
    flex-direction: column;
  }
  .btns-books {
    width: auto;
    position: static;
    margin-right: 0;
  }
  .block-add-book {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: center;
  }
  .block-books-store .sort {
    padding: 5px 0px;
    margin: 5px;
    width: 100%;
  }
  .mobile-inp-check {
    display: flex;
    justify-content: space-between;
  }
  .trash-btn {
    height: 25px;
    width: auto;
  }
  .mobile-id-block {
    display: flex;
  }
  .mobile-book-item {
    display: flex;
    align-items: flex-start;
  }
  .title-book {
    text-align: end;
  }
}
