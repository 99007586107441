.not-found {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.not-found img {
  width: calc(calc(1vh + 1vw) * 30);
}
.not-found a {
  background-color: #cae171;
  padding: 10px 30px;
}
.not-found p {
  text-align: center;
}

@media (max-width: 500px) {
  .not-found img {
    width: 90%;
  }
}
