.accordion__section {
  display: flex;
  flex-direction: column;
}

.search-page {
  display: flex;
  align-items: flex-start;
}

.catalog-search {
  margin-right: 10px;
}

.text-empty {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgb(0, 0, 0);
  font-size: 18px;
}
.text-empty-res {
  background-color: #ffb700;
  padding: 20px 10px;
  font-weight: 600;
}

/* 
.accordion:hover,
.questions .active {
  background-color: #ccc;
} */

.accordion__title {
  font-weight: 600;
  font-size: 14px;
}

.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

.rotate {
  transform: rotate(90deg);
}

.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

.accordion__text {
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}

.link-back {
  font-weight: 600;
  color: #ffb700;
}
@media (max-width: 800px) {
  .search-page {
    flex-direction: column-reverse;
  }
}
