@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
* {
  font-family: "Raleway", sans-serif;
}

.container {
  margin: 15px 8%;
}

:root {
  --color-dark: #4c5f06;
  --color-light: #f8f8f8;
}
.home,
body {
  background-color: var(--color-light);
}
.empty {
  min-height: 600px;
}
.btn a {
  color: white;
}
html,
body {
  margin: 0px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 320px) {
  .container {
    margin: 15px 2%;
  }
}
@media (min-width: 1300px) {
  .container {
    margin: 15px 13%;
  }
}
