.sort {
  background-color: #cae171;
  border: none;
  height: 27px;
  padding: 10px 15px;
  width: 45%;
  font-weight: 600;
  font-size: 15px;
  color: #4c5f06;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sort option {
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.sort option:hover {
  background-color: green;
}

.filter {
  background-color: #cae171;
  border: none;
  padding: 15px;
  width: 50%;
  font-weight: 600;
  font-size: 15px;
  color: #4c5f06;
  text-align: left;
  display: flex;
  justify-content: center;
}
.pag-optionals {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .catalog4{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 30px 0px;
    gap: 10px;
} */
.catalog5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 30px 0px;
  gap: 10px;
}
.img-card {
  /* min-height: 200px; */
  width: 90%;
  /* background-color:#8080802e; */
  margin: 5px auto;
}
.img-card img {
  width: 100%;
  /* min-height: 200px; */
}
.card {
  box-shadow: 0px 0px 20px #b2abab7d;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  justify-content: space-between;
}
.title-card {
  /* text-align: center; */
  font-weight: 600;
  font-size: 13px;
  margin: 0;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* margin: 0px 11px; */
}
.author-card {
  /* text-align: center; */
  margin: 0px;
  font-size: 13px;
}
.about-card {
  font-size: 12px;
  margin: 10px 0px 0px;
}
.desc-cart {
  margin: 0px 10px;
}
.condition-card {
  font-size: 11px;
  margin-left: 10px;
}
.option-card {
  display: flex;
  margin: 5px 0px 0px 10px;
  justify-content: space-between;
}
.price-card {
  font-size: 19px;
  font-weight: 600;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.btn-add-bascet {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffb700;
  color: black;
  padding: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}
.seccess-add {
  width: 15rem;
  text-align: center;
  position: fixed;
  top: 100px;
  left: 38%;
  background-color: #c4c3c3;
  color: black;
  padding: 10px;
  border-radius: 9px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  animation: fadeInDown 3s both;
  z-index: 12;
}
.sort-items {
  text-align: center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../../assets/dropdown-arr.svg);
  background-repeat: no-repeat, repeat;
  background-position: right 5% top 52%, 0 0;
}
.sort-items::after {
  content: 'f';
  color: green;
}
.seccess-add img {
  height: 17px;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0px, -100%, 0px);
  }
  50% {
    opacity: 1;
    transform: none;
  }
  100% {
    opacity: 0;
    transform: translate3d(0px, 100%, 0px);
  }
}
@media (max-width: 1200px) {
  .catalog5 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 30px 0px;
    gap: 10px;
  }
}
@media (max-width: 900px) {
  .seccess-add {
    left: 30%;
  }
  .catalog5 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 30px 0px;
    gap: 10px;
  }
  .price-card {
    font-size: 17px;
  }
}
@media (max-width: 700px) {
  .seccess-add {
    left: 30%;
  }
  .home {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column-reverse;
  }
  .pag-comp {
    width: 100%;
  }
  .category-menu {
    width: 100%;
    margin-bottom: 20px;
  }
  .sort {
    width: 100%;
  }
  /* .catalog5{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin: 30px 0px;
        gap: 10px;
    } */
}
@media (max-width: 540px) {
  .seccess-add {
    left: 8%;
  }
  .catalog5 {
    display: grid;
    grid-template-columns: 48% 48%;
    margin: 30px 0px;
    gap: 10px;
  }
  .option-card {
    display: flex;
    margin: 5px 10px 0px 10px;
    justify-content: space-between;
    flex-direction: column;
  }
  .sort {
    width: 90%;
  }
  .sort-items {
    width: 100%;
  }
  .pag-optionals {
    display: flex;
    height: 100px;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column-reverse;
  }
}
