.img-prod img {
  width: 90%;
}
.cart {
  background-color: white;
}
.all-cart {
  padding: 0px 20px;
}
.delete-prod img {
  width: 30%;
  cursor: pointer;
}
.quantity-prod .book-quantity {
  width: 80%;
  cursor: pointer;
}
.img-prod {
  width: 20%;
}
.description-prod {
  width: 50%;
}
.quantity-prod,
.delete-prod {
  width: 10%;
  text-align: center;
}
.price-prod {
  color: #ffb927;
  font-weight: 600;
}
.brand {
  padding: 10px;
  background-color: #47624766;
  color: white;
  font-weight: 600;
}
.handler-cart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ddf097;
  padding: 0px 20px;
  color: #4c5f06;
}
.btn-delete-cart {
  color: white;
  height: 30px;
  padding: 7px;
  cursor: pointer;
}
.all-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0px;
}
.btn-go-check {
  margin-bottom: 20px;
  min-height: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.summery {
  font-weight: 600;
}
.empty-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.empty-block img {
  height: 300px;
}
.prod-blocked {
  font-weight: 600;
  color: #ff4747;
}
@media (max-width: 540px) {
  .img-prod {
    width: 12%;
  }
  .img-prod img {
    width: 98%;
  }
  .description-prod {
    width: 36%;
    font-size: 10px;
  }
  .price-prod {
    color: #ffb927;
    font-size: 13px;
  }
  .quantity-prod,
  .delete-prod {
    width: 7%;
    text-align: center;
  }
  .all-cart {
    padding: 0;
  }
  .delete-prod img {
    width: 100%;
  }
  .book-quantity {
    padding: 0px 0px;
    font-size: 18px;
  }
  .btn-go-check {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column-reverse;
  }
  .empty-block img {
    width: 50%;
    height: auto;
  }
}
